<template>
  <div class="product-table margin-top">
    <div class="col-12 parts-table-header">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-3" />
            <div class="col-5">
              {{ $t('parts') | capitalize }}
            </div>
            <div class="col-2 align-center">
              {{ $t('quantity') | capitalize }}
            </div>
            <div class="col-2 align-center">
              {{ $t('missing') | capitalize }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 product-table__content">
      <div
        v-for="part in parts"
        :key="part.id"
        class="row parts-table-line"
      >
        <div class="col-12">
          <div class="row align-center">
            <div class="col-3 d-flex justify-start align-center">
              <div class="">
                <router-link
                  :to="{ name: 'Parts Show', params: { partId: part.id }}"
                >
                  <img
                    width="60"
                    height="60"
                    alt=""
                    :src="part.thumb"
                  >
                </router-link>
              </div>
            </div>
            <div class="col-5 align-center">
              <router-link
                class="blue--text"
                :to="{ name: 'Parts Show', params: { partId: part.id }}"
              >
                {{ part.name }}
              </router-link>
            </div>

            <div class="col-2 align-center">
              <slot
                name="quantity"
                :part="part"
              >
              </slot>
            </div>
            <div class="col-2 align-center">
              <slot
                name="missing"
                :part="part"
              >
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartsTable',
  props: {
    parts: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.parts-table-title {
  background: #fff;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.parts-table-header {
  color: #999;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.parts-table-line {
  border-bottom: 1px solid #ddd;
}
</style>
