import Repository from './Repository'

const resource = '/api/orders'

export default {
  index (params) {
    return Repository.get(`${resource}`, { params })
  },
  show (id, params) {
    return Repository.get(`${resource}/${id}`,
      { params })
  },
}
