<template>
  <v-container
    v-if="order"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <v-card style="height: 90%;">
          <v-card-title
            class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
          >
            {{ $t('c.generalInformation') }}
          </v-card-title>
          <v-card-text class="px-8">
            <base-details-panel-item
              :title="$t('title') | capitalize"
              :content="order.client.name"
            />
            <base-details-panel-item
              :title="$t('entity') | capitalize"
              :content="order.entity.name"
            />

            <base-details-panel-item
              :title="$t('status') | capitalize"
              :has-right-panel="false"
            >
              <change-status-button :order="order" />
            </base-details-panel-item>
            <base-details-panel-item
              :title="$t('created') | capitalize"
              :content="order.created_at"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-card style="height: 90%;">
          <v-card-title
            class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
          >
            {{ $t('c.clientAddress') }}
          </v-card-title>
          <v-card-text class="px-8">
            <base-details-panel-item
              class="mb-4"
              :title="$t('shippingCompany') | capitalize"
              :content="order.shipping_company ? order.shipping_company.name : 'Not assigned'"
            />
            <div>
              <p class="ma-0 pa-0 font-weight-bold">
                {{ $t('c.shippingAddress') }}
              </p>
              <p class="ma-0 pa-0">
                {{ order.client.address }} {{ order.client.city }} {{ order.client.post_code }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-card style="height: 90%;">
          <v-card-title
            class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
          >
            {{ $t('c.statusTracking') }}
          </v-card-title>
          <v-card-text class="px-8">
            <base-details-panel-item
              v-for="({slug,created_at, id}, index) in order.statuses_tracking"
              :key="index"
              :title="$t(slug) | humanizeString"
              :content="created_at"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-card>
          <v-card-title
            class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
          >
            {{ $t('c.orderParts') }}
          </v-card-title>
          <v-card-text>
            <parts-table
              :parts="order.parts"
            >
              <template v-slot:quantity="{part}">
                {{ part.stock_need }}
              </template>
              <template v-slot:missing="{part}">
                <span v-if="part.stock_missing === 0"> 0 </span>
                <span
                  v-else
                  class="red--text"
                > {{ part.stock_missing }} </span>
              </template>
            </parts-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrdersRepository from '@/api/OrdersRepository'
import PartsTable from '@/views/dashboard/components/Parts/PartsTable'
import ChangeStatusButton from '@/views/dashboard/components/ChangeStatusButton/ChangeStatusButton'
import EventBus from '@/plugins/EventBus'

export default {
  name: 'OrderShow',
  components: { ChangeStatusButton, PartsTable },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      order: null,
    }
  },

  created () {
    OrdersRepository.show(this.orderId, {
      with: 'client,entity,parts,shippingCompany,currentStatus,orderStatus',
    })
      .then(({ data }) => {
        this.order = data
        const instance = this
        EventBus.$on('orderStatusUpdated', ({ id, status }) => {
          if (instance.order.id === id) {
            instance.order.current_status = status
            instance.order.statuses_tracking.push(status)
          }
        })
      })
  },
  i18n: {
    messages: {
      en: {
        c: {
          generalInformation: 'General Information',
          clientAddress: 'Client Address',
          shippingAddress: 'Shipping address',
          statusTracking: 'Status Tracking',
          orderParts: 'Order Parts',
        },
      },
      it: {
        c: {
          generalInformation: 'Informazione Generale',
          clientAddress: 'Indirizzo del cliente',
          shippingAddress: 'Indirizzo di spedizione',
          statusTracking: 'Monitoraggio dello stato',
          orderParts: 'Ordinare Parti',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
